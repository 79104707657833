/* tslint:disable */
/* eslint-disable */
/**
 * API for the AwsGsaasGSOCBackendLambda
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2018-05-10
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "./base";

/**
 * AssumedRoleUser
 * @export
 * @interface AssumedRoleUser
 */
export interface AssumedRoleUser {
  /**
   * Arn
   * @type {string}
   * @memberof AssumedRoleUser
   */
  arn: string;
  /**
   * AssumedRoleId
   * @type {string}
   * @memberof AssumedRoleUser
   */
  assumedRoleId: string;
}
/**
 * Credentials
 * @export
 * @interface Credentials
 */
export interface Credentials {
  /**
   * accessKeyId
   * @type {string}
   * @memberof Credentials
   */
  accessKeyId: string;
  /**
   * secretAccessKey
   * @type {string}
   * @memberof Credentials
   */
  secretAccessKey: string;
  /**
   * secretAccessKey
   * @type {string}
   * @memberof Credentials
   */
  sessionToken: string;
  /**
   * expiration
   * @type {string}
   * @memberof Credentials
   */
  expiration: string;
}
/**
 *
 * @export
 * @interface EchoRequestContent
 */
export interface EchoRequestContent {
  /**
   *
   * @type {string}
   * @memberof EchoRequestContent
   */
  string?: string;
}
/**
 *
 * @export
 * @interface EchoResponseContent
 */
export interface EchoResponseContent {
  /**
   *
   * @type {string}
   * @memberof EchoResponseContent
   */
  string?: string;
}
/**
 * Endpoint
 * @export
 * @interface Endpoint
 */
export interface Endpoint {
  /**
   * AwsRegion
   * @type {string}
   * @memberof Endpoint
   */
  awsRegion: string;
  /**
   * Endpoint
   * @type {string}
   * @memberof Endpoint
   */
  endpoint?: string;
}
/**
 * GetCredentialsResponse
 * @export
 * @interface GetCredentialsResponseContent
 */
export interface GetCredentialsResponseContent {
  /**
   * RoleName
   * @type {string}
   * @memberof GetCredentialsResponseContent
   */
  roleName: string;
  /**
   *
   * @type {Credentials}
   * @memberof GetCredentialsResponseContent
   */
  credentials: Credentials;
  /**
   *
   * @type {AssumedRoleUser}
   * @memberof GetCredentialsResponseContent
   */
  assumedRoleUser: AssumedRoleUser;
}
/**
 * GetEndpointsResponse
 * @export
 * @interface GetEndpointsResponseContent
 */
export interface GetEndpointsResponseContent {
  /**
   * Stage
   * @type {string}
   * @memberof GetEndpointsResponseContent
   */
  stage: string;
  /**
   * AwsRegion
   * @type {string}
   * @memberof GetEndpointsResponseContent
   */
  awsRegion: string;
  /**
   * ServiceList
   * @type {Array<Service>}
   * @memberof GetEndpointsResponseContent
   */
  serviceList: Array<Service>;
}
/**
 *
 * @export
 * @interface InvalidInputExceptionResponseContent
 */
export interface InvalidInputExceptionResponseContent {
  /**
   *
   * @type {string}
   * @memberof InvalidInputExceptionResponseContent
   */
  message?: string;
}
/**
 * ListRolesResponse
 * @export
 * @interface ListRolesResponseContent
 */
export interface ListRolesResponseContent {
  /**
   * roleList
   * @type {Array<RoleSummary>}
   * @memberof ListRolesResponseContent
   */
  roleList?: Array<RoleSummary>;
}
/**
 * Role Summary
 * @export
 * @interface RoleSummary
 */
export interface RoleSummary {
  /**
   * Unique ID and name of Role
   * @type {string}
   * @memberof RoleSummary
   */
  roleName: string;
  /**
   * Is the user authorized to assume the role
   * @type {boolean}
   * @memberof RoleSummary
   */
  isAuthorized: boolean;
  /**
   * The BindleLock associated with the role
   * @type {string}
   * @memberof RoleSummary
   */
  bindleLock: string;
  /**
   * The permission level associated with the role
   * @type {string}
   * @memberof RoleSummary
   */
  permissionLevel: string;
  /**
   * Description of the role
   * @type {string}
   * @memberof RoleSummary
   */
  description: string;
  /**
   * RoleArn
   * @type {string}
   * @memberof RoleSummary
   */
  roleArn: string;
}
/**
 * Service
 * @export
 * @interface Service
 */
export interface Service {
  /**
   * ServiceName
   * @type {string}
   * @memberof Service
   */
  serviceName: string;
  /**
   * ServiceAuthName to be used when making api calls
   * @type {string}
   * @memberof Service
   */
  serviceAuthName: string;
  /**
   * EndpointList
   * @type {Array<Endpoint>}
   * @memberof Service
   */
  endpointList?: Array<Endpoint>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Echo operation that receive input from body, query-string, and path-parameters.
     * @param {EchoRequestContent} [echoRequestContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    echo: async (
      echoRequestContent?: EchoRequestContent,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/echo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication aws.auth.sigv4 required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        echoRequestContent,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * GetCredentials from an authorized role
     * @param {string} roleName RoleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCredentials: async (
      roleName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'roleName' is not null or undefined
      assertParamExists("getCredentials", "roleName", roleName);
      const localVarPath = `/credentials/{roleName}`.replace(
        `{${"roleName"}}`,
        encodeURIComponent(String(roleName))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication aws.auth.sigv4 required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndpoints: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/endpoints`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication aws.auth.sigv4 required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List of IAM Roles available to the WebFrontEnd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRoles: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/roles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication aws.auth.sigv4 required
      await setApiKeyToObject(
        localVarHeaderParameter,
        "Authorization",
        configuration
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * Echo operation that receive input from body, query-string, and path-parameters.
     * @param {EchoRequestContent} [echoRequestContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async echo(
      echoRequestContent?: EchoRequestContent,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<EchoResponseContent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.echo(
        echoRequestContent,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * GetCredentials from an authorized role
     * @param {string} roleName RoleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCredentials(
      roleName: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetCredentialsResponseContent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCredentials(
        roleName,
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * Get Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEndpoints(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetEndpointsResponseContent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEndpoints(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
    /**
     * List of IAM Roles available to the WebFrontEnd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listRoles(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ListRolesResponseContent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listRoles(
        options
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * Echo operation that receive input from body, query-string, and path-parameters.
     * @param {EchoRequestContent} [echoRequestContent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    echo(
      echoRequestContent?: EchoRequestContent,
      options?: any
    ): AxiosPromise<EchoResponseContent> {
      return localVarFp
        .echo(echoRequestContent, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * GetCredentials from an authorized role
     * @param {string} roleName RoleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCredentials(
      roleName: string,
      options?: any
    ): AxiosPromise<GetCredentialsResponseContent> {
      return localVarFp
        .getCredentials(roleName, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEndpoints(options?: any): AxiosPromise<GetEndpointsResponseContent> {
      return localVarFp
        .getEndpoints(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List of IAM Roles available to the WebFrontEnd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listRoles(options?: any): AxiosPromise<ListRolesResponseContent> {
      return localVarFp
        .listRoles(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * Echo operation that receive input from body, query-string, and path-parameters.
   * @param {EchoRequestContent} [echoRequestContent]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public echo(
    echoRequestContent?: EchoRequestContent,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .echo(echoRequestContent, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * GetCredentials from an authorized role
   * @param {string} roleName RoleName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCredentials(roleName: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCredentials(roleName, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Endpoints
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getEndpoints(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getEndpoints(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List of IAM Roles available to the WebFrontEnd
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listRoles(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listRoles(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
