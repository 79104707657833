import * as React from "react";

import {
  AppLayout,
  AppLayoutProps,
  Box,
  ContentLayout,
  Toggle,
} from "@amzn/awsui-components-react";
import { Navigation } from "../navigation/navigation";
import { applyMode, Mode } from "@amzn/awsui-global-styles";
import {
  DarkModeSettings,
  useDarkModeSettings,
} from "../../context/display-mode/store";
import { useEffect } from "react";

// Properties that can be set on CommonAppLayout when consuming the component.
interface CommonAppLayoutProps extends AppLayoutProps {
  pageId?: string;
}

// CommonAppLayout allows the app to have some default settings for AppLayout
//  such as the navigation and hiding the tools.
function CommonAppLayout(props: CommonAppLayoutProps) {
  const [navigationOpen, setNavigationOpen] = React.useState(
    !!props.navigationOpen
  );

  const { darkMode, setDarkMode } = useDarkModeSettings();

  useEffect(() => {
    applyMode(darkMode ? Mode.Dark : Mode.Light);
  }, [darkMode]);

  const toggleAndNavigation: JSX.Element = (
    <div>
      <Box
        padding={{
          vertical: "s",
        }}
        margin={{
          left: "m",
        }}
      >
        <div style={{ marginRight: "45px" }}>
          <Toggle
            onChange={({ detail }) => setDarkMode(detail.checked)}
            checked={darkMode}
          >
            Dark Mode
          </Toggle>
        </div>
        <Navigation />
      </Box>
    </div>
  );

  return (
    <AppLayout
      {...props}
      stickyNotifications
      navigation={toggleAndNavigation}
      disableContentHeaderOverlap
      content={<ContentLayout>{props.content}</ContentLayout>}
      breadcrumbs={props.breadcrumbs}
      notifications={props.notifications}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
    />
  );
}

export default CommonAppLayout;
