import Box from "@amzn/awsui-components-react/polaris/box";
import CommonAppLayout from "../../components/common/commonLayout";

import { useUserMetadataState } from "../../context/user/store";

// The content in the main content area of the App layout
export default function HomepageContent() {
  return (
    <div>
      <CommonAppLayout
        pageId={"HomePage"}
        content={renderHomepageContent()}
        navigationOpen={true}
        toolsHide
      />
    </div>
  );
}

function renderHomepageContent() {
  const userMetadataState = useUserMetadataState();
  return (
    <>
      <Box variant="p">Welcome {userMetadataState.userName}.</Box>
      <Box variant="p">Customer obsession ensures smooth operations.</Box>
    </>
  );
}
