import { Route, Switch, Redirect } from "react-router-dom";
import ContactSearchContent from "../pages/contact/contact";
import HomepageContent from "../pages/home/HomepageContent";
import { Pages } from "../pages/pages";
import ContactDetailsSection from "../pages/contact/contact-detail";
import ContactListPage from "../pages/contact-list/contact-list";
import AuthorizationContent from "../pages/authorization/authorization";
import { DarkModeSettings } from "../context/display-mode/store";
import { UserMetadata } from "../context/user/store";

// Note well: order of pages matters here.
export default function App() {
  return (
    <UserMetadata>
      <DarkModeSettings>
        {/* TODO:  Update this to something more dynamic instead of the fixed height of the header */}
        <div style={{ marginTop: "56px" }}>
          <Switch>
            <Route
              exact
              path={Pages.Contact}
              component={ContactSearchContent}
            />
            <Route
              path={Pages.ContactDetails}
              component={ContactDetailsSection}
            />
            <Route path={Pages.ContactList} component={ContactListPage} />
            <Route
              path={Pages.Authorization}
              component={AuthorizationContent}
            />
            <Route path={Pages.HomePage} component={HomepageContent} />
            <Redirect to={Pages.HomePage} />
          </Switch>
        </div>
      </DarkModeSettings>
    </UserMetadata>
  );
}
