import { useState } from "react";
import {
  Box,
  Button,
  StatusIndicator,
  StatusIndicatorProps,
  Popover,
} from "@amzn/awsui-components-react";
import { copyToClipboard } from "./clipboard";

import "../styles/components/copy-code.scss";

const SUCCESS_STATUS = "success";
const ERROR_STATUS = "error";

export default function CopyText(props: {
  copyText;
  copyButtonLabel?;
  successText?;
  errorText?;
  hideText?;
}) {
  const [status, setStatus] = useState<StatusIndicatorProps.Type | undefined>(
    SUCCESS_STATUS
  );
  const [message, setMessage] = useState(props.successText ?? "Copied");

  return (
    <div className="custom-wrapping">
      <Box margin={{ right: "xxs" }} display="inline-block">
        <Popover
          size="small"
          position="top"
          dismissButton={false}
          triggerType="custom"
          content={<StatusIndicator type={status}>{message}</StatusIndicator>}
        >
          <Button
            variant="inline-icon"
            iconName="copy"
            ariaLabel={props.copyButtonLabel ?? "Copy"}
            onClick={() => {
              copyToClipboard(props.copyText).then(
                () => {
                  setStatus(SUCCESS_STATUS);
                  setMessage(props.successText ?? "Copied");
                },
                () => {
                  setStatus(ERROR_STATUS);
                  setMessage(props.errorText ?? "Failed to copy");
                }
              );
            }}
          />
        </Popover>
      </Box>
      {props.hideText ? "" : props.copyText}
    </div>
  );
}
