import { ContactListEntry } from "./contact-list-entry";
import {
  Box,
  Button,
  CollectionPreferences,
  Header,
  Pagination,
  PropertyFilter,
  Table,
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { useState } from "react";
import {
  CONTACT_LIST_TABLE_PREFERENCES_KEY,
  DEFAULT_PREFERENCES,
  DEFAULT_VISIBLE_COLUMNS,
  FILTERING_PROPERTIES,
  getListColumnsPreferences,
  LIST_CONTACTS_COLUMN_DEFINITIONS,
  VALID_DETAIL_STATUSES,
} from "./contact-list-table-constants";
import { PROPERTY_FILTERING_I18N_CONSTANTS } from "../../common/constants/common-i18nstring-constants";

export function ContactListTable(props: {
  contacts: ContactListEntry[];
  loading: boolean;
  setSelectedContact?;
}) {
  const defaultPreferencesJson = localStorage.getItem(
    CONTACT_LIST_TABLE_PREFERENCES_KEY
  );
  const defaultPreferences = defaultPreferencesJson
    ? JSON.parse(defaultPreferencesJson)
    : DEFAULT_PREFERENCES;

  const [visibleColumns, setVisibleColumns] = useState<string[]>(
    DEFAULT_VISIBLE_COLUMNS.concat(defaultPreferences.visibleContent)
  );
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(defaultPreferences.pageSize);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(props.contacts, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <Box variant="strong" textAlign="center" color="inherit">
          No contacts found
        </Box>
      ),
      noMatch: (
        <Box variant="strong" textAlign="center" color="inherit">
          No contacts match filters
        </Box>
      ),
    },
    pagination: { pageSize: pageSize },
    sorting: {
      defaultState: { sortingColumn: { sortingField: "prePassStartTime" } },
    },
  });
  const onPreferencesChange = (preferences) => {
    localStorage.setItem(
      CONTACT_LIST_TABLE_PREFERENCES_KEY,
      JSON.stringify(preferences)
    );
    setPageSize(preferences.pageSize);
    setVisibleColumns(
      DEFAULT_VISIBLE_COLUMNS.concat(preferences.visibleContent)
    );
  };
  return (
    <div>
      <Table
        {...collectionProps}
        items={items}
        columnDefinitions={LIST_CONTACTS_COLUMN_DEFINITIONS}
        visibleColumns={visibleColumns}
        loading={props.loading}
        empty={renderEmpty()}
        loadingText="Loading contacts..."
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={true}
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => {
          props.setSelectedContact(
            detail.selectedItems ? detail.selectedItems[0] : undefined
          );
          setSelectedItems(detail.selectedItems);
        }}
        isItemDisabled={(item: ContactListEntry) =>
          VALID_DETAIL_STATUSES.indexOf(item.contactStatus ?? "") === -1
        }
        selectionType="single"
        trackBy="contactId"
        header={
          <Header variant="h2">
            List Contacts Results ({filteredItemsCount}/{props.contacts.length})
          </Header>
        }
        filter={
          <PropertyFilter
            i18nStrings={{
              ...PROPERTY_FILTERING_I18N_CONSTANTS,
              filteringPlaceholder: "Find Contact",
            }}
            {...propertyFilterProps}
          />
        }
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <ContactListTablePreferences
            onChange={onPreferencesChange}
            defaultPreferences={defaultPreferences}
          />
        }
      />
    </div>
  );
}

function ContactListTablePreferences(props: { onChange; defaultPreferences? }) {
  const [preferences, setPreferences] = useState<any>(
    props.defaultPreferences ?? DEFAULT_PREFERENCES
  );
  return (
    <CollectionPreferences
      title="Select Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      onConfirm={({ detail }) => {
        setPreferences(detail);
        props.onChange(detail);
      }}
      preferences={preferences}
      visibleContentPreference={getListColumnsPreferences()}
      pageSizePreference={{
        title: "Select page size",
        options: [
          { value: 25, label: "25 contacts" },
          { value: 50, label: "50 contacts" },
          { value: 100, label: "100 contacts" },
        ],
      }}
    />
  );
}

function renderEmpty() {
  return (
    <Box textAlign="center" color="inherit">
      <b>No resources</b>
    </Box>
  );
}
