import {
  ButtonDropdownProps,
  TopNavigation,
} from "@amzn/awsui-components-react";
import { NAV_I18NSTRINGS } from "../../common/constants/common-i18nstring-constants";
import { LOGO_BASE_64 } from "../../assets/gs_icons/logo";
import { appStage } from "../../common/constants/const-settings-util";
import { accountsHelper } from "../../common/utils/accounts-util";

import "../../styles/top-navigation.scss";

export default function CommonHeader() {
  const generateGsocUrl = (region: string): string => {
    const endpoint = accountsHelper
      .getAccount("opsconsole", appStage(), region)
      ?.services.find((service) => service.service_key === "gsoc")?.endpoint;
    return `https://${endpoint}/#`;
  };

  const generateRegionSelectionItems = (): ButtonDropdownProps.Items => {
    return accountsHelper
      .getAccounts("opsconsole", appStage())
      .map((account) => {
        return {
          id: account.region_abbr,
          text: account.region,
          href: `${generateGsocUrl(account.region)}`,
        };
      });
  };

  return (
    <div>
      <TopNavigation
        identity={{
          href: "#",
          title: "AWS Ground Station - Operations Console (GSOC)",
          logo: {
            src: LOGO_BASE_64,
            alt: "GSOC",
          },
        }}
        utilities={[
          {
            type: "menu-dropdown",
            text: "Region Selection",
            description: "Select Ops Console Region",
            items: generateRegionSelectionItems(),
          },
        ]}
        i18nStrings={NAV_I18NSTRINGS}
      />
    </div>
  );
}
