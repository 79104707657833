import { useState } from "react";
import {
  BreadcrumbGroup,
  Button,
  Container,
  Form,
  Header,
  FormField,
  SpaceBetween,
  Textarea,
} from "@amzn/awsui-components-react";

import { appLayoutLabels } from "../../common/constants/labels";
import { contactBreadcrumbsList } from "./contact-components";
import CommonAppLayout from "../../components/common/commonLayout";

export const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={contactBreadcrumbsList()}
    expandAriaLabel="Show path"
    ariaLabel="Breadcrumbs"
  />
);

const ContactSearch = () => {
  const [contactId, setContactId] = useState("");
  const routeChange = () => {
    // TODO augment validation to include that it is a uuid.
    if (contactId === undefined) {
      return;
    }
    const path = `/#/contact/${contactId}`;
    history.pushState({}, "Contact Details", path);
    history.go();
  };

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={() => routeChange()}>
              Locate Contact Details
            </Button>
          </SpaceBetween>
        }
      >
        <Container header={<Header variant="h2">Contact search</Header>}>
          <SpaceBetween size="l">
            <FormField
              label={<>Contact ID (UUID)</>}
              description="Search for a specific Contact by ID. "
              constraintText="Only one Contact ID is supported"
              stretch={false}
            >
              <Textarea
                placeholder="aaaaaaaa-bbbb-cccc-dddd-cccccccccccc"
                value={contactId}
                onChange={({ detail }) => setContactId(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
    </form>
  );
};

export default function ContactSearchContent() {
  return (
    <CommonAppLayout
      content={<ContactSearch />}
      navigationOpen={true}
      breadcrumbs={<Breadcrumbs />}
      ariaLabels={appLayoutLabels}
    />
  );
}
