export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: "your choice",
  dismissAriaLabel: "Dismiss",

  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",

  operationAndText: "and",
  operationOrText: "or",

  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",

  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",
  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text) => `Use: "${text}"`,
};

export const DATE_RANGE_I18N_CONSTANTS = {
  todayAriaLabel: "Today",
  nextMonthAriaLabel: "Next month",
  previousMonthAriaLabel: "Previous month",
  customRelativeRangeDurationLabel: "Duration",
  customRelativeRangeDurationPlaceholder: "Enter duration",
  customRelativeRangeOptionLabel: "Custom range",
  customRelativeRangeOptionDescription: "Set a custom range in the past",
  customRelativeRangeUnitLabel: "Unit of time",
  formatRelativeRange: (e) => {
    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
    return `Last ${e.amount} ${t}`;
  },
  formatUnit: (e, t) => (1 === t ? e : `${e}s`),
  dateTimeConstraintText:
    "Range must be between 6 and 30 days. Use 24 hour format.",
  relativeModeTitle: "Relative range",
  absoluteModeTitle: "Absolute range",
  relativeRangeSelectionHeading: "Choose a range",
  startDateLabel: "Start date",
  endDateLabel: "End date",
  startTimeLabel: "Start time",
  endTimeLabel: "End time",
  clearButtonLabel: "Clear and dismiss",
  cancelButtonLabel: "Cancel",
  applyButtonLabel: "Apply",
};

export const SPLIT_PANEL_I18NSTRINGS = {
  closeButtonAriaLabel: "Close",
  openButtonAriaLabel: "Open",
  preferencesTitle: "Preferences",
  preferencesPositionLabel: "Position",
  preferencesPositionDescription: "Choose preferred position",
  preferencesPositionSide: "Side Panel",
  preferencesPositionBottom: "Bottom Panel",
  preferencesConfirm: "Confirm",
  preferencesCancel: "Cancel",
  resizeHandleAriaLabel: "Resize",
};

export const NAV_I18NSTRINGS = {
  searchIconAriaLabel: "Search",
  searchDismissIconAriaLabel: "Close search",
  overflowMenuTriggerText: "More",
  overflowMenuTitleText: "All",
  overflowMenuBackIconAriaLabel: "Back",
  overflowMenuDismissIconAriaLabel: "Close menu",
};
