import { CredentialProvider, Credentials } from "@aws-sdk/types";
import { fromTemporaryCredentials } from "@aws-sdk/credential-providers";
import GSOCApiFactory from "../gsoc-api/GSOCApiFactory";

const read_only_role_name = "AwsGsaasGSOCOpsReadOnly";
const read_write_role_name = "AwsGsaasGSOCOpsReadWrite";

export function getReadOnlyTemporaryCredentialProvider(
  roleArn: string,
  userName: string
): CredentialProvider {
  // Will provide a ChainTemporaryCredentials
  // The GSOC ReadOnly role will be used to assume the passed in roleArn.
  let sessionName = `${userName!}-OpsConsole`;
  console.log(`Session name: ${sessionName}`);
  return fromTemporaryCredentials({
    params: {
      RoleArn: roleArn,
      RoleSessionName: sessionName,
    },
    masterCredentials: gsocTemporaryCredentials({
      roleName: read_only_role_name,
    }),
    clientConfig: {
      endpoint: "https://sts.us-east-1.amazonaws.com",
      region: "us-east-1",
    },
  });
}
export interface GsocTemporaryCredentialsOptions {
  roleName: string;
}

export const gsocTemporaryCredentials = (
  options: GsocTemporaryCredentialsOptions
): CredentialProvider => {
  const GSOCApi = GSOCApiFactory();
  return async (): Promise<Credentials> => {
    const gsocCredentials = await GSOCApi.getCredentials(options.roleName);
    return {
      accessKeyId: gsocCredentials.data.credentials.accessKeyId,
      secretAccessKey: gsocCredentials.data.credentials.secretAccessKey,
      sessionToken: gsocCredentials.data.credentials.sessionToken,
      expiration: new Date(gsocCredentials.data.credentials.expiration),
    };
  };
};
