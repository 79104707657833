import { useEffect, useState } from "react";

import {
  Box,
  Container,
  Header,
  Icon,
  SpaceBetween,
  Tabs,
} from "@amzn/awsui-components-react";

import { useParams } from "react-router-dom";

import { Navigation } from "../../components/navigation/navigation";
import { appLayoutLabels } from "../../common/constants/labels";
import ToolsContent from "./tools-content";
import CommonAppLayout from "../../components/common/commonLayout";
import { generateBreadcrumbs } from "../../common/utils/breadcrumbUtils";
import { InfoLink } from "../../components/common/links";
import {
  contactBreadcrumbsList,
  ContactSummary,
  DataflowDetails,
  SatelliteDetails,
  VisualizationDetails,
} from "./contact-components";
import { PageHeader } from "../../components/headers/PageHeader";
import { DescribeContactInternalCommandOutput } from "@amzn/aws-gsaas-control-plane-internal-client";
import { loadContactDetail } from "../../clients/gs-internal-client-wrapper";

import { useUserMetadataState } from "../../context/user/store";
import {
  loadContactVisualization,
  visualizationJsonMap,
} from "./contact-visualization";

const DataflowSection = ({
  contactInformation,
  setContactContentState,
}: {
  contactInformation: DescribeContactInternalCommandOutput;
  setContactContentState?: any;
}) => (
  <Container
    header={
      <Header
        variant="h2"
        info={
          setContactContentState && (
            <InfoLink
              id="dataflowInfo"
              onFollow={() =>
                setContactContentState({ toolsIndex: 1, toolsOpen: true })
              }
              ariaLabel={"Information about dataflow used in contact."}
            />
          )
        }
      >
        Dataflows
      </Header>
    }
  >
    <DataflowDetails describe_contact_internal_response={contactInformation} />
  </Container>
);

const SatelliteSection = ({ contactInformation, setContactContentState }) => (
  <Container
    header={
      <Header
        variant="h2"
        info={
          setContactContentState && (
            <InfoLink
              id="satelliteInfo"
              onFollow={() =>
                setContactContentState({ toolsIndex: 2, toolsOpen: true })
              }
              ariaLabel={"Information about satellite used in contact."}
            />
          )
        }
      >
        Satellite
      </Header>
    }
  >
    <SatelliteDetails describe_contact_internal_response={contactInformation} />
  </Container>
);

const VisualizationSection = ({
  visualizationUrls,
  setContactContentState,
}) => (
  <Container
    header={
      <Header
        variant="h2"
        info={
          setContactContentState && (
            <InfoLink
              id="visualizationInfo"
              onFollow={() =>
                setContactContentState({ toolsIndex: 3, toolsOpen: true })
              }
              ariaLabel={"Information about satellite used in contact."}
            />
          )
        }
      >
        Visualization <Icon name="lock-private" variant="error" />
      </Header>
    }
  >
    <VisualizationDetails visualizationUrls={visualizationUrls} />
  </Container>
);

export function ContactDetailTabs(props: {
  contactInformation;
  setContactContentState?;
  visualizationUrls;
}) {
  if (!props.contactInformation) {
    return <></>;
  }
  return (
    <Tabs
      tabs={[
        {
          label: "Dataflow",
          id: "dataflow",
          content: (
            <DataflowSection
              setContactContentState={props.setContactContentState}
              contactInformation={props.contactInformation}
            />
          ),
        },
        {
          label: "Satellite",
          id: "satellite",
          content: (
            <SatelliteSection
              setContactContentState={props.setContactContentState}
              contactInformation={props.contactInformation}
            />
          ),
        },
        {
          label: `Visualization [I]`,
          id: "visualization",
          content: (
            <VisualizationSection
              visualizationUrls={props.visualizationUrls}
              setContactContentState={props.setContactContentState}
            />
          ),
        },
      ]}
      ariaLabel="Contact details"
    />
  );
}

interface IContactContentState {
  toolsIndex: number;
  toolsOpen: boolean;
}

export default function ContactDetailsSection(props: { contactId? }) {
  const [contactInformation, setContactInformation] =
    useState<DescribeContactInternalCommandOutput>({ $metadata: {} });

  const [contactContentState, setContactContentState] =
    useState<IContactContentState>({
      toolsIndex: 0,
      toolsOpen: false,
    });

  const [visualizationUrls, setVisualizationUrls] =
    useState<visualizationJsonMap>();

  const { contactId } = props.contactId ? props : useParams<any>();

  const userMetadataState = useUserMetadataState();

  useEffect(() => {
    loadContactVisualization(userMetadataState.userName!, contactId).then((v) =>
      setVisualizationUrls(v)
    );
    loadContactDetail(contactId, userMetadataState.userName!).then((detail) =>
      setContactInformation(detail)
    );
  }, []);
  return (
    <Box>
      <CommonAppLayout
        contentHeader={
          <Box>
            <PageHeader buttons={undefined} contactId={contactId} />
          </Box>
        }
        content={
          <SpaceBetween size="l">
            <ContactSummary
              describe_contact_internal_response={contactInformation}
            />
            <ContactDetailTabs
              contactInformation={contactInformation}
              setContactContentState={setContactContentState}
              visualizationUrls={visualizationUrls}
            />
          </SpaceBetween>
        }
        breadcrumbs={generateBreadcrumbs(contactBreadcrumbsList(contactId))}
        navigation={<Navigation />}
        tools={ToolsContent[contactContentState.toolsIndex]}
        toolsOpen={contactContentState.toolsOpen}
        onToolsChange={({ detail }) =>
          setContactContentState({
            toolsOpen: detail.open,
            toolsIndex: contactContentState.toolsIndex,
          })
        }
        ariaLabels={appLayoutLabels}
        toolsHide={false}
      />
    </Box>
  );
}
