import { TableProps } from "@amzn/awsui-components-react/polaris/table/interfaces";
import { ContactListEntry } from "./contact-list-entry";
import CopyText from "../../components/copy-text";
import {
  Badge,
  Button,
  Link,
  Popover,
  PropertyFilterProps,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { CollectionPreferencesProps } from "@amzn/awsui-components-react/polaris/collection-preferences/interfaces";
import { PostPassAnalysisLink } from "../../components/common/links";
import FilteringProperty = PropertyFilterProps.FilteringProperty;

export const VALID_DETAIL_STATUSES: readonly string[] = [
  "FAILED",
  "AWS_FAILED",
  "PREPASS",
  "PASS",
  "POSTPASS",
  "SCHEDULED",
];
const createStatusBadge = (contactStatus) => {
  switch (contactStatus) {
    case "COMPLETED":
      return <Badge color="green">{contactStatus}</Badge>;

    case "FAILED":
    case "FAILED_TO_SCHEDULE":
    case "AWS_FAILED":
      return <Badge color="red">{contactStatus}</Badge>;

    case "PREPASS":
    case "PASS":
    case "POSTPASS":
      return <Badge color="blue">{contactStatus}</Badge>;

    default:
      return <Badge>{contactStatus}</Badge>;
  }
};

const formatElapsedTime = (deltaMs): string => {
  const sign = deltaMs >= 0 ? "+" : "-";
  if (deltaMs < 0) {
    deltaMs = -deltaMs;
  }
  const deltaS = Math.floor(deltaMs / 1000);
  let seconds = String(deltaS % 60);
  let minutes = String(Math.floor(deltaS / 60) % 60);
  let hours = String(Math.floor(deltaS / 3600));

  if (hours.length < 2) {
    hours = "0" + hours;
  }
  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }
  if (seconds.length < 2) {
    seconds = "0" + seconds;
  }
  return sign + hours + ":" + minutes + ":" + seconds;
};

const accountText = (id, internalName) => {
  if (internalName) {
    return (
      <span style={{ color: "cyan" }}>
        <Popover
          size="small"
          position="top"
          dismissButton={false}
          triggerType="custom"
          content={
            <StatusIndicator type="info">{internalName}</StatusIndicator>
          }
        >
          <Button iconName="user-profile" variant="icon" />
        </Popover>
        {id}
      </span>
    );
  } else {
    return id;
  }
};

const PAGE_SIZE = 50;

export const CONTACT_LIST_TABLE_PREFERENCES_KEY =
  "CONTACT_LIST_TABLE_PREFERENCES";

export const DEFAULT_PREFERENCES = {
  pageSize: PAGE_SIZE,
  wrapLines: true,
  visibleContent: [],
};

export const FILTERING_PROPERTIES: ReadonlyArray<FilteringProperty> = [
  {
    key: "customerId",
    propertyLabel: "Customer ID",
    groupValuesLabel: "Customer ID values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "contactId",
    propertyLabel: "Contact ID",
    groupValuesLabel: "Contact ID values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "satelliteId",
    propertyLabel: "Satellite ID",
    groupValuesLabel: "Satellite ID values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "satelliteCatalogNumber",
    propertyLabel: "CatNum",
    groupValuesLabel: "CatNum values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "noradName",
    propertyLabel: "NoradName",
    groupValuesLabel: "NoradName values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "groundStation",
    propertyLabel: "Ground Station",
    groupValuesLabel: "Ground Station values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "antennaName",
    propertyLabel: "Antenna Name",
    groupValuesLabel: "Antenna Name values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "contactStatus",
    propertyLabel: "Status",
    groupValuesLabel: "Status values",
    operators: [":", "!:", "=", "!="],
  },
  {
    key: "internalExternal",
    propertyLabel: "Internal/External",
    groupValuesLabel: "Internal/External values",
    operators: ["="],
  },
  {
    key: "region",
    propertyLabel: "Destination Region",
    groupValuesLabel: "Destination Region values",
    operators: [":", "!:", "=", "!="],
  },
];

export const LIST_CONTACTS_COLUMN_DEFINITIONS: readonly TableProps.ColumnDefinition<ContactListEntry>[] =
  [
    {
      id: "customerId",
      sortingField: "customerId",
      header: "Customer ID",
      cell: (item) => (
        <div>
          {accountText(item.customerId, item.internalCustomerAccountName)}
          <CopyText copyText={item.customerId} hideText={true} />
        </div>
      ),
    },
    {
      id: "contactId",
      sortingField: "contactId",
      header: "Contact ID",
      cell: (item) => (
        <div>
          <Link href={`/#/contact/${item.contactId}`} target="_blank">
            {item.contactId}
          </Link>
          <CopyText copyText={item.contactId} hideText={true} />
        </div>
      ),
    },
    {
      id: "satelliteId",
      sortingField: "satelliteId",
      header: "Satellite ID",
      cell: (item) => item.satelliteId,
    },
    {
      id: "satelliteCatalogNumber",
      sortingField: "satelliteCatalogNumber",
      header: "CatNum",
      cell: (item) => item.satelliteCatalogNumber,
    },
    {
      id: "noradName",
      sortingField: "noradName",
      header: "NoradName",
      cell: (item) => item.noradName,
    },
    {
      id: "prePassStartTime",
      sortingField: "prePassStartTime",
      header: "Pre Pass",
      cell: (item) => item.prePassStartTime?.toUTCString(),
    },
    {
      id: "startTime",
      sortingField: "startTime",
      header: "AOS",
      cell: (item) => item.startTime?.toUTCString(),
    },
    {
      id: "endTime",
      sortingField: "endTime",
      header: "LOS",
      cell: (item) => item.endTime?.toUTCString(),
    },
    {
      id: "duration",
      sortingField: "duration",
      header: "Duration",
      cell: (item) =>
        formatElapsedTime(item.endTime!.getTime() - item.startTime!.getTime()),
    },
    {
      id: "eta",
      sortingField: "eta",
      header: "ETA (hours)",
      cell: (item) =>
        formatElapsedTime(item.startTime!.getTime() - new Date().getTime()),
    },
    {
      id: "groundStation",
      sortingField: "groundStation",
      header: "Ground Station",
      cell: (item) => item.groundStation,
    },
    {
      id: "antennaName",
      sortingField: "antennaName",
      header: "Antenna Name",
      cell: (item) => item.antennaName,
    },
    {
      id: "destinationRegion",
      sortingField: "destinationRegion",
      header: "Destination Region",
      cell: (item) => item.region,
    },
    {
      id: "contactStatus",
      sortingField: "contactStatus",
      header: "Status",
      cell: (item) => createStatusBadge(item.contactStatus),
    },
    {
      id: "postPassAnalysisResults",
      sortingField: "postPassAnalysisResults",
      header: "PostPass Analysis Results",
      cell: (item) => (
        <PostPassAnalysisLink
          contactId={item.contactId}
          contactStatus={item.contactStatus}
        />
      ),
    },
    {
      id: "internalExternal",
      sortingField: "internalExternal",
      header: "Internal/External",
      cell: (item) =>
        item.internalCustomerAccountName ? "INTERNAL" : "EXTERNAL",
    },
  ];

export const getListColumnsPreferences =
  (): CollectionPreferencesProps.VisibleContentPreference => {
    let options: any[] = LIST_CONTACTS_COLUMN_DEFINITIONS.filter(
      (item) => DEFAULT_VISIBLE_COLUMNS.indexOf(item.id!) === -1
    ).map((item) => {
      return {
        label: item.header,
        id: item.id,
      };
    });
    return {
      title: "Select visible columns",
      options: [
        {
          label: "Optional Columns",
          options: options,
        },
      ],
    };
  };

export const DEFAULT_VISIBLE_COLUMNS = [
  "customerId",
  "contactId",
  "noradName",
  "startTime",
  "endTime",
  "antennaName",
  "destinationRegion",
  "contactStatus",
  "postPassAnalysisResults",
  "internalExternal",
];
