import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";

export interface IDarkMode {
  darkMode: boolean;
  setDarkMode: Dispatch<SetStateAction<boolean>>;
}

export const DarkModeContext = createContext<IDarkMode>({
  darkMode: window.matchMedia("(prefers-color-scheme: dark)").matches,
  setDarkMode: () => {},
});

/**
 * Preferences context.
 *
 * Used to track preferences settings for the current session.
 *
 * @param children Nested components
 * @returns
 */
export const DarkModeSettings: React.FunctionComponent = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

/**
 * Use the DarkModeContext
 * @returns IDarkMode
 */
export function useDarkModeSettings(): IDarkMode {
  const context = React.useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error("darkmode must be used within a DarkModeContext");
  }
  return context;
}
