import { ContactListEntry } from "./contact-list-entry";
import { SPLIT_PANEL_I18NSTRINGS } from "../../common/constants/common-i18nstring-constants";
import { SpaceBetween, SplitPanel } from "@amzn/awsui-components-react";
import { ContactDetailTabs } from "../contact/contact-detail";
import { ContactSummary } from "../contact/contact-components";
import { useEffect, useState } from "react";
import { DescribeContactInternalCommandOutput } from "@amzn/aws-gsaas-control-plane-internal-client";
import { loadContactDetail } from "../../clients/gs-internal-client-wrapper";

import { useUserMetadataState } from "../../context/user/store";
import {
  loadContactVisualization,
  visualizationJsonMap,
} from "../contact/contact-visualization";

export function ContactListSplitDetails(props: { contact: ContactListEntry }) {
  if (!props.contact) {
    return <></>;
  }
  const [contactInformation, setContactInformation] =
    useState<DescribeContactInternalCommandOutput>({ $metadata: {} });

  const userMetadataState = useUserMetadataState();
  const [visualizationUrls, setVisualizationUrls] =
    useState<visualizationJsonMap>();

  useEffect(() => {
    loadContactVisualization(
      userMetadataState.userName!,
      props.contact.contactId!
    ).then((v) => setVisualizationUrls(v));
    loadContactDetail(
      props.contact.contactId!,
      userMetadataState.userName!
    ).then((detail) => setContactInformation(detail));
  }, [props.contact]);
  return (
    <SplitPanel
      header={props.contact.contactId!}
      i18nStrings={SPLIT_PANEL_I18NSTRINGS}
    >
      <SpaceBetween size="l">
        <ContactSummary
          describe_contact_internal_response={contactInformation}
        />
        <ContactDetailTabs
          contactInformation={contactInformation}
          visualizationUrls={visualizationUrls}
        />
      </SpaceBetween>
    </SplitPanel>
  );
}
