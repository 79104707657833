import { getAppSetting } from "../../config/AppSettings";

/**
 * These helper functions pull from the settings.json file. This is either
 * locally performed or as part of the deployment via CDK.
 */
export const appStage = (): string => `${getAppSetting("stage")}`;
export const appRegion = (): string => `${getAppSetting("region")}`;
export const appAccountId = (): string => `${getAppSetting("accountId")}`;
export const apiBasePath = (): string => `${getAppSetting("apiUrl")}/jwt/v1`;
