export type Pages = string;
/***
 * Define the pages and routing parameters needed.
 */
export const Pages = {
  HomePage: "/",
  Authorization: "/authorization",
  Contact: "/contact",
  ContactDetails: "/contact/:contactId",
  ContactList: "/contacts",
};
