import {
  BreadcrumbGroup,
  BreadcrumbGroupProps,
} from "@amzn/awsui-components-react";

export const generateBreadcrumbs = (
  breadcrumbs: BreadcrumbGroupProps.Item[]
) => {
  return (
    <BreadcrumbGroup
      items={breadcrumbs}
      expandAriaLabel="Show path"
      ariaLabel="Breadcrumbs"
    />
  );
};
