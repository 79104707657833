import * as cpInternalModels from "@amzn/aws-gsaas-control-plane-internal-client";
import {
  ContactDataInternal,
  GroundStationDataInternalListEntry,
  GroundStationInternal,
  GroundStationType,
  ListContactsInternalCommandInput,
} from "@amzn/aws-gsaas-control-plane-internal-client";
import { customRegionInfoProvider } from "./gs/cpInternal/regionInfoProvider";
import { getReadOnlyTemporaryCredentialProvider } from "./gsocCredentialsProvider";
import { accountsHelper } from "../common/utils/accounts-util";
import {
  ContactListEntry,
  convert,
} from "../pages/contact-list/contact-list-entry";
import { appRegion, appStage } from "../common/constants/const-settings-util";
import { common_cfn_read_only_role_name, getRoleArn } from "./gs/gsRoleUtils";

/**
 * Build the GS Internal Client given the region and the stage
 * This can be done by using the accounts helper
 */
const ALPHA_BETA_REGION_OVERRIDES = new Map<string, string>([
  ["us-east-1", "us-east-2"],
]);

export function getGSInternalClientForRegion(
  region: string,
  userName: string
): GroundStationInternal {
  const stage = appStage();
  const inAlphaOrBeta = ["alpha", "beta"].indexOf(stage) !== -1;
  // Override certain regions only in alpha/beta
  region = inAlphaOrBeta
    ? ALPHA_BETA_REGION_OVERRIDES.get(region) ?? region
    : region;
  console.log(`Creating GS internal account for ${region} on stage ${stage}`);
  let account = accountsHelper.getAccount("controlplane", stage, region)!;
  let service = account.services.find(
    (service) => service.service_key == "controlplaneinternal"
  )!;
  console.log("Found endpoint: ", service.endpoint);

  let roleArn = getRoleArn(account.account_id, common_cfn_read_only_role_name);
  let credentialProviderGs = getReadOnlyTemporaryCredentialProvider(
    roleArn,
    userName
  );
  const clientInternal: cpInternalModels.GroundStationInternal =
    new cpInternalModels.GroundStationInternal({
      credentials: credentialProviderGs,
      endpoint: `https://${service.endpoint}`,
      region,
      regionInfoProvider: customRegionInfoProvider,
    });
  return clientInternal;
}

const isGroundStationTypeProduction = (
  type: GroundStationType | string | undefined
): boolean => (type ? "PRODUCTION".localeCompare(type) === 0 : false);
export const pad = (num, size: number) => ("0000000000" + num).substr(-size);
export const toIso8601Date = (date: Date): string =>
  `${date.getUTCFullYear()}-${pad(date.getUTCMonth() + 1, 2)}-${pad(
    date.getUTCDate(),
    2
  )}T${pad(date.getUTCHours(), 2)}:${pad(date.getUTCMinutes(), 2)}:${pad(
    date.getUTCSeconds(),
    2
  )}`;

export const loadContactDetail = async (contactId: string, userName: string) =>
  await getGSInternalClientForRegion(
    appRegion(),
    userName
  ).describeContactInternal({
    contactId,
  });

export const loadGroundStations = async (
  userName: string
): Promise<GroundStationDataInternalListEntry[]> => {
  let client = getGSInternalClientForRegion(appRegion(), userName);

  let nextToken: string | undefined = undefined;
  let stations: GroundStationDataInternalListEntry[] = [];
  do {
    let response = await client.internalListGroundStations({
      nextToken: nextToken,
    });
    stations = stations.concat(
      response.groundStationList?.filter((gs) =>
        isGroundStationTypeProduction(gs.type)
      )
    );
    nextToken = response.nextToken;
  } while (nextToken != undefined);
  console.log("Loaded production stations: ", stations);
  return stations;
};
export const loadContacts = async (
  userName: string,
  groundStation: GroundStationDataInternalListEntry,
  startTime: Date,
  endTime: Date
): Promise<ContactListEntry[]> => {
  let finalContactList: ContactListEntry[] = [];
  let client = getGSInternalClientForRegion(
    groundStation.region ?? appRegion(),
    userName
  );
  let nextToken: string | undefined;
  do {
    let request: ListContactsInternalCommandInput = {
      startTime: startTime,
      endTime: endTime,
      groundStation: groundStation.groundStationId,
      nextToken: nextToken,
    };
    try {
      let results = await client.listContactsInternal(request);
      let contactList: ContactDataInternal[] = results.contactList ?? [];
      let contactListEntries: ContactListEntry[] = contactList.map((contact) =>
        convert(contact)
      );

      finalContactList = finalContactList.concat(contactListEntries);

      console.log(
        `Found ${contactListEntries.length} contacts at ${groundStation.groundStationName}`
      );

      nextToken = results.nextToken;
    } catch (e) {
      console.log("Encountered error while listing contacts", e);
    }
  } while (nextToken != null);
  return finalContactList;
};
