import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import {
  COLUMN_DEFINITIONS,
  getMatchesCountText,
  EmptyState,
  paginationLabels,
} from "./table-config";
import GSOCApiFactory from "../../../gsoc-api/GSOCApiFactory";
import { RoleSummary } from "../../../gsoc-api/generated-src";

export default function RoleSummaryTable() {
  const [allItems, setAllItems] = useState<RoleSummary[]>();

  const GSOCApi = GSOCApiFactory();

  useEffect(() => {
    (async () => {
      const gsocListRolesResponse = await GSOCApi.listRoles();
      setAllItems(gsocListRolesResponse.data.roleList);
    })();
  }, []);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allItems || [], {
    filtering: {
      empty: (
        <EmptyState
          title="No records"
          subtitle="No records to display."
          action={undefined}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: {
      pageSize: 10,
    },
    sorting: {},
    selection: {},
  });

  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
      loading={!allItems}
      loadingText="Loading instances"
      selectionType="single"
      header={
        <Header
          counter={
            allItems &&
            (selectedItems!.length
              ? `(${selectedItems!.length}/${allItems.length})`
              : `(${allItems.length})`)
          }
        >
          Records
        </Header>
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={getMatchesCountText(filteredItemsCount!)}
          filteringAriaLabel="Filter records"
        />
      }
    />
  );
}
