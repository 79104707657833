import Box from "@amzn/awsui-components-react/polaris/box";
import Grid from "@amzn/awsui-components-react/polaris/grid";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Header from "@amzn/awsui-components-react/polaris/header";
import Link from "@amzn/awsui-components-react/polaris/link";
import DetailsTable from "./roleTable/Table";

import CommonAppLayout from "../../components/common/commonLayout";

// The content in the main content area of the App layout
export default function AuthorizationContent() {
  return (
    <div>
      <CommonAppLayout
        pageId={"Authorization"}
        content={renderContent()}
        navigationOpen={false}
        toolsHide
      />
    </div>
  );
}

function renderContent() {
  return (
    <>
      <div>
        <Box padding={{ top: "xxl", horizontal: "s", bottom: "l" }}>
          <Grid
            gridDefinition={[
              {
                colspan: { xl: 10, l: 9, s: 10, xxs: 10 },
                offset: { l: 2, xxs: 1 },
              },
            ]}
          >
            <SpaceBetween size="l">
              <div>
                <Header variant="h1" headingTagOverride="h2">
                  Authorization
                </Header>
                <Box variant="p">
                  What this ops console is capable of doing is dependent on what
                  you are authorized to do. Mutating roles, roles that allow
                  modifying parameters, are restricted. If you are not seeing a
                  particular function, check if you are authorized in the table
                  below. If you are not authorized, check your inclusion within
                  the Teams attached to the respective Bindle.
                </Box>
                <Box variant="p">
                  <Link href="https://bindles.amazon.com/resource/amzn1.bindle.resource.d22gu4j3opy63zeldycq">
                    AwsGsaasGSOCOpsReadWrite
                  </Link>
                </Box>
                <Box variant="p">
                  <Link href="https://bindles.amazon.com/resource/amzn1.bindle.resource.n7jf5jfz23uonyc4j4eq">
                    AwsGsaasGSOCOpsReadOnly
                  </Link>
                </Box>

                <Container>
                  <DetailsTable />
                </Container>
              </div>
            </SpaceBetween>
          </Grid>
        </Box>
      </div>
    </>
  );
}
