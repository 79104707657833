import { GroundStationDataInternalListEntry } from "@amzn/aws-gsaas-control-plane-internal-client";
import { useEffect, useState } from "react";
import {
  OptionDefinition,
  OptionGroup,
} from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import {
  DateRangePicker,
  Multiselect,
  SelectProps,
} from "@amzn/awsui-components-react";
import { DATE_RANGE_I18N_CONSTANTS } from "../../common/constants/common-i18nstring-constants";

export function GroundStationSelect(props: {
  groundStations: GroundStationDataInternalListEntry[];
  setSelectedGroundStations;
  disabled?: boolean;
}) {
  const [options, setOptions] = useState<OptionGroup[]>([]);
  const [groundStationOptions, setGroundStationOptions] = useState<
    readonly OptionDefinition[]
  >([]);
  const handleChange = ({ detail }) => {
    setGroundStationOptions(detail.selectedOptions);
    props.setSelectedGroundStations(
      detail.selectedOptions.map((gs) => JSON.parse(gs.value))
    );
  };
  useEffect(() => {
    const groupOptions: OptionDefinition[] = [];
    props.groundStations.forEach((value) => {
      groupOptions.push({
        label: value.groundStationName,
        value: JSON.stringify(value),
        description: value.region,
      });
    });
    handleChange({ detail: { selectedOptions: groupOptions } });
    const newOptions: OptionGroup[] = [
      {
        label: "Ground Station",
        options: groupOptions,
      },
    ];
    setOptions(newOptions);
  }, [props.groundStations]);

  return (
    <Multiselect
      selectedOptions={groundStationOptions}
      disabled={props.disabled}
      placeholder="Choose groundstations"
      loadingText="Loading groundstations..."
      statusType={props.groundStations.length > 0 ? "finished" : "loading"}
      options={options}
      onChange={handleChange}
    />
  );
}

export function DefaultDateRangePicker(props: {
  dateRange;
  setDateRange;
  disabled?: boolean;
}) {
  return (
    <DateRangePicker
      placeholder="Select date"
      disabled={props.disabled}
      rangeSelectorMode="absolute-only"
      onChange={({ detail }) => props.setDateRange(detail.value)}
      isValidRange={() => ({ valid: true })}
      value={props.dateRange}
      relativeOptions={[]}
      i18nStrings={DATE_RANGE_I18N_CONSTANTS}
    />
  );
}
