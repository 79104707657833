import * as React from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { Pages } from "../../pages/pages";
import {
  SideNavigation,
  SideNavigationProps,
} from "@amzn/awsui-components-react";

const navigationItems: ReadonlyArray<SideNavigationProps.Item> = [
  {
    type: "section",
    text: "Home",
    items: [
      { type: "link", text: "Home", href: Pages.HomePage + location.search },
    ],
  },
  {
    type: "section",
    text: "Authorization",
    items: [
      {
        type: "link",
        text: "View Authorization",
        href: Pages.Authorization + location.search,
      },
    ],
  },
  {
    type: "section",
    text: "Contacts",
    items: [
      {
        type: "link",
        text: "List Contacts",
        href: Pages.ContactList + location.search,
      },
      {
        type: "link",
        text: "View Individual Contact",
        href: Pages.Contact + location.search,
      },
    ],
  },
];

class NavigationSidebar extends React.Component<RouteComponentProps<any>> {
  render() {
    return (
      <div>
        <SideNavigation
          items={navigationItems}
          onFollow={this.onFollowHandler.bind(this)}
        />
      </div>
    );
  }

  onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      this.props.history.push(ev.detail.href);
    }
  }
}

export const Navigation = withRouter(NavigationSidebar);
