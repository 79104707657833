import React, { createContext, useEffect, useState } from "react";
import { getUsername } from "../../auth/user";

export interface IUserMetadata {
  userName: string | undefined;
}
export const UserMetadataContext = createContext<IUserMetadata>({
  userName: undefined,
});

/**
 * User metadata context.
 *
 * Used to store username (et al) for use to construct traceable permissions.
 * Username is retrieved from JWT and is required in order to render any child pages.
 * @param children Nested components
 * @returns
 */
export const UserMetadata: React.FunctionComponent = ({ children }) => {
  const [userMetadata, setUserMetadata] = useState<IUserMetadata>({
    userName: undefined,
  });
  useEffect(() => {
    getUsername()
      .then((userName) => setUserMetadata({ userName: userName }))
      .catch((e) => console.log("ERROR - cannot determine username"));
  });
  return (
    <UserMetadataContext.Provider value={userMetadata}>
      {userMetadata.userName && children}
    </UserMetadataContext.Provider>
  );
};

/**
 * Use the UserMetadataState
 * @returns IUserMetadata
 */
export function useUserMetadataState(): IUserMetadata {
  const context = React.useContext(UserMetadataContext);
  if (context === undefined) {
    throw new Error("userMetadata must be used within a UserMetadataContext");
  }
  return context;
}
