import { Link } from "@amzn/awsui-components-react";
import { externalLinkProps } from "../../common/constants/labels";
import {
  appAccountId,
  appRegion,
  appStage,
} from "../../common/constants/const-settings-util";

export const InfoLink = ({ id, onFollow, ariaLabel }) => (
  <Link variant="info" id={id} onFollow={onFollow} ariaLabel={ariaLabel}>
    Info
  </Link>
);

// a special case of external link, to be used within a link group, where all of them are external
// and we do not repeat the icon
export const ExternalLinkItem = ({ href, text }) => (
  <Link
    href={href}
    ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`}
    target="_blank"
  >
    {text}
  </Link>
);

const VALID_POSTPASS_STATUSES = ["COMPLETED", "FAILED", "AWS_FAILED"];
export const PostPassAnalysisLink = (props: { contactId; contactStatus }) => {
  if (VALID_POSTPASS_STATUSES.indexOf(props.contactStatus) === -1) return <></>;
  const url =
    `https://isengard.amazon.com/federate?account=${appAccountId()}` +
    `&role=ReadOnly&destination=s3/buckets/data-analytics-${appStage()}-${appRegion()}-${appAccountId()}/` +
    `contact_trending_charts/${
      props.contactId
    }/?region=${appRegion()}&tab=overview`;
  return (
    <Link external href={url}>
      link
    </Link>
  );
};
