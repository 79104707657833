import {
  Button,
  ButtonDropdown,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";

function getButtonAction(buttons) {
  if (buttons === undefined) {
    return undefined;
  }
  return (
    <SpaceBetween direction="horizontal" size="xs">
      {buttons.map((button, key) =>
        !button.items ? (
          <Button
            href={button.href || ""}
            disabled={button.disabled || false}
            key={key}
          >
            {button.text}
          </Button>
        ) : (
          <ButtonDropdown items={button.items} key={key}>
            {button.text}
          </ButtonDropdown>
        )
      )}
    </SpaceBetween>
  );
}

export const PageHeader = ({ contactId, buttons }) => {
  return (
    <Header variant="h1" actions={getButtonAction(buttons)}>
      {contactId}
    </Header>
  );
};
