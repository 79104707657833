import { GetObjectCommandOutput, S3 } from "@aws-sdk/client-s3";
import {
  appRegion,
  appStage,
} from "../../common/constants/const-settings-util";
import { accountsHelper } from "../../common/utils/accounts-util";
import {
  data_analytics_read_only_role_name,
  getRoleArn,
} from "../../clients/gs/gsRoleUtils";
import { getReadOnlyTemporaryCredentialProvider } from "../../clients/gsocCredentialsProvider";

const streamToString = async (stream: ReadableStream): Promise<string> => {
  const chunks: any = [];
  const DECODER = new TextDecoder("utf8");
  const myStringBuffer = await stream.getReader().read();
  const myString = DECODER.decode(myStringBuffer.value);
  console.log(`stream2String: ${myString}`);
  return myString;
};

export interface visualizationJsonMap {
  dataplane_visualization_svg_url_light: string;
  dataplane_visualization_svg_url_dark: string;
}

/**
 * Loads the contact visualization url to gather an embbedable SVG image
 * @param userName gsoc user
 * @param contactId
 */
export const loadContactVisualization = async (
  userName: string,
  contactId: string
): Promise<visualizationJsonMap> => {
  const stage = appStage();
  const region = appRegion();
  // Analytic accounts are staged in IAD and PDX, this matches the ops console.
  // No need to get complicated - we can assume the local region is healthy and if not the user should switch regions entirely.
  let analyticAccount = accountsHelper.getAccount("analytics", stage, region)!;
  let analyticAccountId = analyticAccount.account_id;

  // Collect credentials and generate client
  let roleArn = getRoleArn(
    analyticAccountId,
    data_analytics_read_only_role_name
  );
  let credentialProvider = getReadOnlyTemporaryCredentialProvider(
    roleArn,
    userName
  );
  let s3Client = new S3({
    region: region,
    credentials: credentialProvider,
  });

  const Bucket = `gs-data-analytics-${stage}-${region}-${analyticAccountId}`;
  const Key = `contact/${contactId}/visualization/visualization_metadata.json`;

  console.log(`Fetching s3://${Bucket}/${Key}`);
  let jsonObj: visualizationJsonMap | undefined = undefined;
  if (Bucket && Key) {
    jsonObj = await s3Client
      .getObject({ Bucket, Key })
      .then((value: GetObjectCommandOutput) => {
        const body = value.Body as ReadableStream;
        return body;
      })
      .then((value: ReadableStream) => {
        let myString = streamToString(value)
          .then((localString) => {
            return localString;
          })
          .catch((err) => {
            throw err;
          });
        return myString;
      })
      .then((value: string) => {
        let jsonItem = JSON.parse(value);
        console.log(`final visualization json: ${JSON.stringify(jsonItem)}`);
        return jsonItem;
      })
      .catch((err) => {
        console.log("ERROR: Occurred during S3 get object request.");
        console.log(err);
      });
  }
  if (jsonObj === undefined) {
    throw new Error(
      "ERROR: Could not retrieve or parse visulization json from s3."
    );
  }

  return jsonObj;
};
