/* eslint-disable react/jsx-key */
import { HelpPanel, Icon } from "@amzn/awsui-components-react";
import { ExternalLinkItem } from "../../components/common/links";

export default [
  <ContactToolTip />,
  <DataflowToolTip />,
  <SatelliteToolTip />,
  <VisualizationToolTip />,
  <HelpPanel
    header={<h2>Tags</h2>}
    footer={
      <>
        <h3>
          Learn more{" "}
          <span role="img" aria-label="Icon external Link">
            <Icon name="external" />
          </span>
        </h3>
        <ul>
          <li>
            <ExternalLinkItem
              href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/tagging.html"
              text="Tagging Amazon CloudFront Distributions"
            />
          </li>
        </ul>
      </>
    }
  >
    <p>
      Tags are words or phrases that you can use to identify and organize your
      AWS resources. You can add multiple tags to each resource, and each tag
      includes a key and a value that you define. For example, the key might be
      "domain" and the value might be "example.com". You can search and filter
      your resources based on the tags you add.
    </p>
  </HelpPanel>,
];

function ContactToolTip() {
  return (
    <HelpPanel header={<h2>Contact</h2>} footer={CommonToolFooter()}>
      Describe Contacts.
    </HelpPanel>
  );
}

function DataflowToolTip() {
  return (
    <HelpPanel header={<h2>Dataflow</h2>} footer={CommonToolFooter()}>
      Describe Dataflow.
    </HelpPanel>
  );
}

function SatelliteToolTip() {
  return (
    <HelpPanel header={<h2>Satellite</h2>} footer={CommonToolFooter()}>
      Describe Satellite.
    </HelpPanel>
  );
}
function VisualizationToolTip() {
  return (
    <HelpPanel header={<h2>Visualization</h2>} footer={CommonToolFooter()}>
      Describe Visualization.
    </HelpPanel>
  );
}

function CommonToolFooter() {
  return (
    <>
      <h3>
        Learn more{" "}
        <span role="img" aria-label="Icon external Link">
          <Icon name="external" />
        </span>
      </h3>
      <ul>
        <li>
          <ExternalLinkItem
            href="https://docs.aws.amazon.com/ground-station/latest/ug/index.html"
            text="AWS Ground Station User Guide"
          />
        </li>
      </ul>
    </>
  );
}
