import { useEffect, useState } from "react";
import { GroundStationDataInternalListEntry } from "@amzn/aws-gsaas-control-plane-internal-client";
import {
  Box,
  Button,
  ExpandableSection,
  FormField,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { Navigation } from "../../components/navigation/navigation";
import { appLayoutLabels } from "../../common/constants/labels";
import {
  DefaultDateRangePicker,
  GroundStationSelect,
} from "./contact-list-header";
import { ContactListTable } from "./contact-list-table";
import CommonAppLayout from "../../components/common/commonLayout";
import { ContactListSplitDetails } from "./contact-list-split-details";
import {
  loadContacts,
  loadGroundStations,
  toIso8601Date,
} from "../../clients/gs-internal-client-wrapper";
import { useUserMetadataState } from "../../context/user/store";

export default function ContactListPage() {
  const [groundStations, setGroundStations] = useState<any[]>([]);
  const [selectedGroundStations, setSelectedGroundStations] = useState<
    GroundStationDataInternalListEntry[]
  >([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [expandedHeader, setExpandedHeader] = useState<boolean>(true);
  const [selectedContact, setSelectedContact] = useState<any>();

  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [dateRange, setDateRange] = useState<any>({
    type: "absolute",
    startDate: toIso8601Date(yesterday),
    endDate: toIso8601Date(now),
  });

  // TODO: Error handling on bad requests to display on UI

  const userMetadataState = useUserMetadataState();
  useEffect(() => {
    loadGroundStations(userMetadataState.userName!)
      .then((groundStations) => setGroundStations(groundStations))
      .catch((e) => console.log("Error loading ground stations: ", e));
  }, []);

  let listContacts = async () => {
    setShowTable(true);
    setExpandedHeader(false);
    setLoadingContacts(true);
    setContacts([]);
    let promises = selectedGroundStations.map((groundStation) =>
      loadContacts(
        userMetadataState.userName!,
        groundStation,
        new Date(dateRange.startDate),
        new Date(dateRange.endDate)
      )
    );
    await Promise.all(promises).then((results) => {
      let contacts = results.reduce((acc, val) => acc.concat(val), []);
      console.log("Setting contacts: ", contacts);
      setContacts(contacts);
      setLoadingContacts(false);
    });
  };

  return (
    <CommonAppLayout
      contentHeader={
        <Box variant="div">
          <ExpandableSection
            defaultExpanded={true}
            variant="container"
            expanded={expandedHeader}
            onChange={() => setExpandedHeader(!expandedHeader)}
            header={<Header variant="h1">Contact List</Header>}
          >
            <SpaceBetween direction="vertical" size="xs">
              <FormField label="Time Range (UTC)">
                <DefaultDateRangePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  disabled={loadingContacts}
                />
              </FormField>
              <FormField label="Ground Stations to Load">
                <GroundStationSelect
                  groundStations={groundStations}
                  setSelectedGroundStations={setSelectedGroundStations}
                  disabled={loadingContacts}
                />
              </FormField>
              <Button
                variant="primary"
                loading={groundStations.length === 0}
                disabled={
                  selectedGroundStations.length === 0 || loadingContacts
                }
                onClick={listContacts}
              >
                List Contacts
              </Button>
            </SpaceBetween>
          </ExpandableSection>
        </Box>
      }
      content={
        <div>
          <SpaceBetween direction="vertical" size="xs">
            {showTable ? (
              <ContactListTable
                contacts={contacts}
                loading={loadingContacts}
                setSelectedContact={setSelectedContact}
              />
            ) : (
              <div />
            )}
          </SpaceBetween>
        </div>
      }
      navigation={<Navigation />}
      ariaLabels={appLayoutLabels}
      navigationOpen={false}
      disableContentHeaderOverlap={true}
      splitPanel={<ContactListSplitDetails contact={selectedContact} />}
      splitPanelOpen={!!selectedContact}
      onSplitPanelToggle={({ detail }) =>
        detail.open
          ? setSelectedContact(selectedContact)
          : setSelectedContact(undefined)
      }
    />
  );
}
