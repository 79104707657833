import { ContactDataInternal } from "@amzn/aws-gsaas-control-plane-internal-client";
import { accountsHelper } from "../../common/utils/accounts-util";
import norad_names from "norad_names";

export interface ContactListEntry extends ContactDataInternal {
  internalCustomerAccountName?: string;
  noradName?: string;
}

export function convert(contact: ContactDataInternal) {
  const internal =
    accountsHelper.accountIds.indexOf(contact.customerId!) !== -1;
  let internalCustomerAccountName: string | undefined;
  if (internal) {
    internalCustomerAccountName =
      accountsHelper.awsAccounts[contact.customerId!].name;
  }
  const noradName = contact.satelliteCatalogNumber
    ? norad_names[contact.satelliteCatalogNumber]
    : undefined;
  return {
    internalCustomerAccountName,
    noradName,
    ...contact,
  };
}
