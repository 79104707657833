/**
 * This file is a copy-paste of the generated endpoint file to tweak the signingService.
 * https://prod.artifactbrowser.brazil.aws.dev/packages/AwsGsaasControlPlaneInternalTypeScriptClient/versions/1.0.60.0/platforms/AL2_x86_64/flavors/DEV.STD.PTHREAD/smithyprojections/AwsGsaasControlPlaneInternalTypeScriptClient/aws-sdk-external/typescript-codegen/src/endpoints.ts
 *
 */
import {
  PartitionHash,
  RegionHash,
  getRegionInfo,
} from "@aws-sdk/config-resolver";
import { RegionInfoProvider, RegionInfoProviderOptions } from "@aws-sdk/types";

import * as cpInternalModels from "@amzn/aws-gsaas-control-plane-internal-client";
const regionHash: RegionHash = {
  "us-east-1": {
    variants: [
      {
        hostname: "groundstation-fips.us-east-1.amazonaws.com",
        tags: ["fips"],
      },
    ],
  },
  "us-east-2": {
    variants: [
      {
        hostname: "groundstation-fips.us-east-2.amazonaws.com",
        tags: ["fips"],
      },
    ],
  },
  "us-west-2": {
    variants: [
      {
        hostname: "groundstation-fips.us-west-2.amazonaws.com",
        tags: ["fips"],
      },
    ],
  },
};

const partitionHash: PartitionHash = {
  aws: {
    regions: [
      "af-south-1",
      "ap-east-1",
      "ap-northeast-1",
      "ap-northeast-2",
      "ap-northeast-3",
      "ap-south-1",
      "ap-southeast-1",
      "ap-southeast-2",
      "ap-southeast-3",
      "ca-central-1",
      "eu-central-1",
      "eu-north-1",
      "eu-south-1",
      "eu-west-1",
      "eu-west-2",
      "eu-west-3",
      "fips-us-east-1",
      "fips-us-east-2",
      "fips-us-west-2",
      "me-south-1",
      "sa-east-1",
      "us-east-1",
      "us-east-2",
      "us-west-1",
      "us-west-2",
    ],
    regionRegex: "^(us|eu|ap|sa|ca|me|af)\\-\\w+\\-\\d+$",
    variants: [
      {
        hostname: "groundstation.{region}.amazonaws.com",
        tags: [],
      },
      {
        hostname: "groundstation-fips.{region}.amazonaws.com",
        tags: ["fips"],
      },
      {
        hostname: "groundstation-fips.{region}.api.aws",
        tags: ["dualstack", "fips"],
      },
      {
        hostname: "groundstation.{region}.api.aws",
        tags: ["dualstack"],
      },
    ],
  },
  "aws-cn": {
    regions: ["cn-north-1", "cn-northwest-1"],
    regionRegex: "^cn\\-\\w+\\-\\d+$",
    variants: [
      {
        hostname: "groundstation.{region}.amazonaws.com.cn",
        tags: [],
      },
      {
        hostname: "groundstation-fips.{region}.amazonaws.com.cn",
        tags: ["fips"],
      },
      {
        hostname: "groundstation-fips.{region}.api.amazonwebservices.com.cn",
        tags: ["dualstack", "fips"],
      },
      {
        hostname: "groundstation.{region}.api.amazonwebservices.com.cn",
        tags: ["dualstack"],
      },
    ],
  },
  "aws-iso": {
    regions: ["us-iso-east-1", "us-iso-west-1"],
    regionRegex: "^us\\-iso\\-\\w+\\-\\d+$",
    variants: [
      {
        hostname: "groundstation.{region}.c2s.ic.gov",
        tags: [],
      },
      {
        hostname: "groundstation-fips.{region}.c2s.ic.gov",
        tags: ["fips"],
      },
    ],
  },
  "aws-iso-b": {
    regions: ["us-isob-east-1"],
    regionRegex: "^us\\-isob\\-\\w+\\-\\d+$",
    variants: [
      {
        hostname: "groundstation.{region}.sc2s.sgov.gov",
        tags: [],
      },
      {
        hostname: "groundstation-fips.{region}.sc2s.sgov.gov",
        tags: ["fips"],
      },
    ],
  },
  "aws-us-gov": {
    regions: ["us-gov-east-1", "us-gov-west-1"],
    regionRegex: "^us\\-gov\\-\\w+\\-\\d+$",
    variants: [
      {
        hostname: "groundstation.{region}.amazonaws.com",
        tags: [],
      },
      {
        hostname: "groundstation-fips.{region}.amazonaws.com",
        tags: ["fips"],
      },
      {
        hostname: "groundstation-fips.{region}.api.aws",
        tags: ["dualstack", "fips"],
      },
      {
        hostname: "groundstation.{region}.api.aws",
        tags: ["dualstack"],
      },
    ],
  },
};

export const customRegionInfoProvider: RegionInfoProvider = async (
  region: string,
  options?: RegionInfoProviderOptions
) =>
  getRegionInfo(region, {
    ...options,
    signingService: "execute-api",
    regionHash,
    partitionHash,
  });
