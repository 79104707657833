import {
  AwsGroundStationAgentEndpoint,
  DataflowDetail,
  DataflowEndpoint,
  DescribeContactInternalCommandOutput,
  Destination,
  S3RecordingDetails,
  SecurityDetails,
} from "@amzn/aws-gsaas-control-plane-internal-client";
import {
  Box,
  ColumnLayout,
  Container,
  Header,
  StatusIndicator,
  SpaceBetween,
  Icon,
  Spinner,
  BreadcrumbGroupProps,
  ExpandableSection,
} from "@amzn/awsui-components-react";
import React from "react";
import CopyText from "../../components/copy-text";

import norad_names from "norad_names";

export const contactBreadcrumbsList = (
  contactId: string | undefined = undefined
): BreadcrumbGroupProps.Item[] => {
  const breadcrumbs = [
    {
      text: "AWS Ground Station",
      href: "#",
    },
    {
      text: "Contact",
      href: "#",
    },
  ];

  return contactId
    ? [
        ...breadcrumbs,
        {
          text: `${contactId}`,
          href: "#",
        },
      ]
    : [...breadcrumbs];
};

export const S3RecordingDetailSummary = ({
  s3RecordingDetails,
}: {
  s3RecordingDetails: S3RecordingDetails;
}) => (
  <ExpandableSection
    header={<Header variant="h2">S3 Recording Details</Header>}
    defaultExpanded
    variant="container"
  >
    <SpaceBetween size="m">
      <div>
        <Box variant="awsui-key-label">Bucket ARN</Box>
        <div>{s3RecordingDetails.bucketArn}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Key Template</Box>
        <div>{s3RecordingDetails.keyTemplate}</div>
      </div>
    </SpaceBetween>
  </ExpandableSection>
);

export const AgentEndpointDetailSummary = ({
  endpoint,
}: {
  endpoint: AwsGroundStationAgentEndpoint;
}) => (
  <ExpandableSection
    header={<Header variant="h2">Agent Endpoint Details</Header>}
    defaultExpanded
    variant="container"
  >
    <SpaceBetween size="m">
      <div>
        <Box variant="awsui-key-label">Endpoint Name</Box>
        <div>{endpoint.name}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Ingress Address</Box>
        <div>{endpoint.ingressAddress}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Egress Address</Box>
        <div>{endpoint.ingressAddress}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Agent Status</Box>
        <StatusIndicator
          type={endpoint.agentStatus === "HEALTHY" ? "success" : "info"}
        >
          {endpoint.agentStatus}
        </StatusIndicator>
      </div>
    </SpaceBetween>
  </ExpandableSection>
);

export const LegacyEndpointDetailSummary = ({
  endpoint,
  securityDetails,
}: {
  endpoint: DataflowEndpoint;
  securityDetails: SecurityDetails;
}) => (
  <ExpandableSection
    header={<Header variant="h2">Legacy Endpoint Details</Header>}
    defaultExpanded
    variant="container"
  >
    <SpaceBetween size="m">
      <div>
        <Box variant="awsui-key-label">Endpoint Name</Box>
        <div>{endpoint.name}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Address</Box>
        <div>
          {endpoint.address?.name}:{endpoint.address?.port}
        </div>
      </div>
      {endpoint.mtu && (
        <div>
          <Box variant="awsui-key-label">MTU</Box>
          <div>{endpoint.mtu}</div>
        </div>
      )}
      <div>
        <Box variant="awsui-key-label">
          Status <Icon name="lock-private" variant="error" />
        </Box>
        <StatusIndicator
          type={endpoint.status === "HEALTHY" ? "success" : "info"}
        >
          {endpoint.status}
        </StatusIndicator>
      </div>
      <div>
        <Box variant="awsui-key-label">Role ARN</Box>
        <div>{securityDetails.roleArn}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Security Group Ids</Box>
        <div>{securityDetails.securityGroupIds?.join(",")}</div>
      </div>
      <div>
        <Box variant="awsui-key-label">Subnet Ids</Box>
        <div>{securityDetails.subnetIds?.join(",")}</div>
      </div>
    </SpaceBetween>
  </ExpandableSection>
);

export const DataflowDestinationDetails = ({
  destination,
}: {
  destination: Destination;
}) => (
  <Box>
    {(destination.configDetails?.s3RecordingDetails && (
      <S3RecordingDetailSummary
        s3RecordingDetails={destination.configDetails.s3RecordingDetails}
      />
    )) ||
      (destination.configDetails?.endpointDetails
        ?.awsGroundStationAgentEndpoint && (
        <AgentEndpointDetailSummary
          endpoint={
            destination.configDetails.endpointDetails
              .awsGroundStationAgentEndpoint
          }
        />
      )) ||
      (destination.configDetails?.endpointDetails?.endpoint &&
        destination.configDetails?.endpointDetails?.securityDetails && (
          <LegacyEndpointDetailSummary
            endpoint={destination.configDetails.endpointDetails.endpoint}
            securityDetails={
              destination.configDetails.endpointDetails.securityDetails
            }
          />
        ))}
    {/* TODO: Implement other destination types */}
  </Box>
);

export const ContactSummary = ({
  describe_contact_internal_response,
}: {
  describe_contact_internal_response: DescribeContactInternalCommandOutput;
}) => (
  <Container header={<Header variant="h2">Contact Details</Header>}>
    {describe_contact_internal_response.contactData ? (
      <ColumnLayout columns={4} variant="text-grid" borders="vertical">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Contact ID</Box>
            <div>
              {describe_contact_internal_response.contactData.contactId}
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">
              Customer ID <Icon name="lock-private" variant="error" />
            </Box>
            <div>
              {describe_contact_internal_response.contactData.customerId}
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Satellite Catalog Number</Box>
            <div>
              {
                describe_contact_internal_response.contactData
                  .satelliteCatalogNumber
              }
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Satellite ID </Box>
            <div>
              {describe_contact_internal_response.contactData.satelliteId}
            </div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Ground Station</Box>
            <div>
              {describe_contact_internal_response.contactData.groundStation}
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">
              Antenna Name <Icon name="lock-private" variant="error" />
            </Box>
            <div>
              {describe_contact_internal_response.contactData.antennaName}
            </div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Contact Status</Box>
            <StatusIndicator
              type={
                describe_contact_internal_response.contactData.contactStatus ===
                "AWS_FAILED"
                  ? "error"
                  : describe_contact_internal_response.contactData
                      .contactStatus === "COMPLETED"
                  ? "success"
                  : "info"
              }
            >
              {describe_contact_internal_response.contactData.contactStatus}
            </StatusIndicator>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Mission Profile ARN</Box>
            <CopyText
              copyText={
                describe_contact_internal_response.contactData.missionProfileArn
              }
              copyButtonLabel="Copy ARN"
              successText="ARN copied"
              errorText="ARN failed to copy"
            />
          </div>
          <div>
            <Box variant="awsui-key-label">Mission Profile ID</Box>
            <div>
              {describe_contact_internal_response.contactData.missionProfileId}
            </div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    ) : (
      <Spinner size="large" />
    )}
  </Container>
);

const renderDataflow = (dataflows: Array<DataflowDetail>) => {
  return React.Children.toArray(
    dataflows.map((dataflow_details, index) => {
      return dataflow_details ? (
        <>
          <div id={`source-${index}`}>
            <Container header={<Header variant="h2">Source</Header>}>
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Config ID</Box>
                  <div>{dataflow_details?.source?.configId}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Config Type</Box>
                  <div>{dataflow_details?.source?.configType}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Region</Box>
                  <div>{dataflow_details?.source?.dataflowSourceRegion}</div>
                </div>
              </SpaceBetween>
            </Container>
          </div>
          <div id={`destination-${index}`}>
            <Container header={<Header variant="h2">Destination</Header>}>
              <SpaceBetween size="l">
                <div>
                  <Box variant="awsui-key-label">Config ID</Box>
                  <div>{dataflow_details?.destination?.configId}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Config Type</Box>
                  <div>{dataflow_details?.destination?.configType}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Region</Box>
                  <div>
                    {dataflow_details?.destination?.dataflowDestinationRegion}
                  </div>
                </div>
                <div>
                  {dataflow_details.destination?.configDetails && (
                    <DataflowDestinationDetails
                      destination={dataflow_details.destination}
                    />
                  )}
                </div>
              </SpaceBetween>
            </Container>
          </div>
        </>
      ) : (
        <div></div>
      );
    })
  );
};

export const DataflowDetails = ({
  describe_contact_internal_response,
}: {
  describe_contact_internal_response: DescribeContactInternalCommandOutput;
}) => (
  <ColumnLayout columns={2} borders="horizontal">
    {describe_contact_internal_response &&
      describe_contact_internal_response.contactData &&
      describe_contact_internal_response.contactData.dataflowList &&
      renderDataflow(
        describe_contact_internal_response.contactData.dataflowList
      )}
  </ColumnLayout>
);

export const SatelliteDetails = ({
  describe_contact_internal_response,
}: {
  describe_contact_internal_response: DescribeContactInternalCommandOutput;
}) => {
  const satelliteCatalogNumber =
    describe_contact_internal_response.contactData?.satelliteCatalogNumber ?? 0;
  const noradName = norad_names[satelliteCatalogNumber];

  return (
    <Container header={<Header variant="h2">Satellite Details</Header>}>
      <ColumnLayout columns={2} variant="text-grid" borders="vertical">
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Satellite Catalog Number</Box>
            <div>
              {
                describe_contact_internal_response.contactData
                  ?.satelliteCatalogNumber
              }
            </div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">Satellite ID </Box>
            <div>
              {describe_contact_internal_response.contactData?.satelliteId}
            </div>
          </div>
        </SpaceBetween>
        <SpaceBetween size="l">
          <div>
            <Box variant="awsui-key-label">
              Satellite Name (NORAD){" "}
              <Icon name="lock-private" variant="error" />{" "}
            </Box>
            <div>{noradName}</div>
          </div>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export const VisualizationDetails = ({ visualizationUrls }) => {
  if (
    visualizationUrls !== undefined &&
    visualizationUrls.dataplane_visualization_svg_url_light
  ) {
    const url = visualizationUrls.dataplane_visualization_svg_url_light;
    return (
      <Container header={<Header variant="h2">Dataplane Visualization</Header>}>
        <object data={url} type="image/svg+xml"></object>
      </Container>
    );
  } else {
    return <Box variant="h2">Visualization unavailable.</Box>;
  }
};
